import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export class ParallaxEffectContained {
  constructor(el) {
    this.container = el;
    this.mm = gsap.matchMedia();
    this.elements = this.container.querySelectorAll("[data-parallax-target]");
    this.animate();
  }

  MouseMoveHandler(e) {
    this.elements.forEach((item, i) => {
      
      const itemObserver = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {

            if (entry.isIntersecting) {

              let elem = entry.target;
  
              let animFactor = 0.05;
              const parent = this.container;
              const parentRect = parent.getBoundingClientRect();
              const deltaX = (e.clientX - (parentRect.left + parentRect.width / 2)) * animFactor * (i / 2);
              const deltaY = (e.clientY - (parentRect.top + parentRect.height / 2)) * animFactor * (i / 2);
  
              gsap.to(item, {
                x: deltaX,
                y: deltaY,
                duration: 1,
                ease: "power3.out",
                overwrite: true,
              });
              
            }
              
          });
        },
        {
          rootMargin: "0px",
          threshold: 0.25,
        }
      );
    
      itemObserver.observe(item);

    });
  }

  animate() {
    this.mm.add("(min-width: 1024px)", () => {

      document.addEventListener(
        "mousemove",
        this.MouseMoveHandler.bind(this),
        false
      );
      
      return () => {
        document.removeEventListener(
          "mousemove",
          this.MouseMoveHandler.bind(this),
          false
        );
        gsap.killTweensOf(this.elements);
      };

    });
  }
}
