import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export class BackgroundColor {
  constructor(el, i) {
    this.sections = gsap.utils.toArray("[data-bg]");
    this.animate();
  }
  animate() {
    const scrollTop = window.scrollY;

    const sectionObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          var fontSwapItems =
              entry.target.querySelectorAll(".font-colour-swap"),
            backgroundFrom = entry.target.dataset.bgfrom,
            backgroundTo = entry.target.dataset.bgto;

          if (entry.isIntersecting && entry.intersectionRatio <= 0.75) {
            gsap.to("body", {
              background: `linear-gradient(to right, ${backgroundFrom} 0%, ${backgroundTo} 100%)`,
              overwrite: "auto",
            });

            if (fontSwapItems) {
              fontSwapItems.forEach((fontSwapItem) => {
                fontSwapItem.classList.add("active");
              });
            }
          } else {
            if (scrollTop > 100) {
              if (fontSwapItems) {
                fontSwapItems.forEach((fontSwapItem) => {
                  fontSwapItem.classList.remove("active");
                });
              }
            }
          }
        });
      },
      {
        rootMargin: "0px",
        threshold: 0.25,
      }
    );

    this.sections.forEach((section) => {
      document.addEventListener("scroll", function (e) {
        sectionObserver.observe(section);
      });
    });
  }
}
