import { gsap } from "gsap";

export class SlideReveal {
  constructor(el) {
    this.el = el;
    this.isPlaying = false;
    this.leftEls = el.querySelectorAll("[data-slide-reveal-target-left]");
    this.rightEls = el.querySelectorAll("[data-slide-reveal-target-right]");
    this.init();
  }
  animate(element, dir) {
    let x = dir == 0 ? -50 : 50;
    gsap.fromTo(
      element,
      {
        xPercent: x,
        opacity: 0,
        willChange: "transform, opacity",
      },
      {
        ease: "power3",
        xPercent: 0,
        opacity: 1,
        overwrite: "auto",
        scrollTrigger: {
          trigger: this.el,
          start: "top bottom-=10%",
          end: "top center",
          scrub: 2,
        },
      }
    );
  }
  init() {
    this.leftEls.forEach((element) => this.animate(element, 0));
    this.rightEls.forEach((element) => this.animate(element, 1));
  }
}
