import { gsap } from "gsap";

export class VideoCard {
  constructor(el) {
    this.el = el;
    this.isPlaying = false;
    this.video = this.el.querySelector("[data-video-card-target]");
    this.video.pause();
    this.init();
  }
  init() {
    this.video.addEventListener("ended", (e) => {
      this.isPlaying = false;
      this.video.pause();
      this.video.currentTime = 0;
    });
    this.el.addEventListener("mouseenter", (e) => {
      if (!this.isPlaying) {
        this.video.play();
        this.isPlaying = true;
      }
    });
    this.el.addEventListener("mouseleave", (e) => {
      if (this.isPlaying) {
        this.video.pause();
        this.isPlaying = false;
      }
    });
  }
}
