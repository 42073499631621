import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

import Lenis from "lenis";

export class SmoothPageScroll {
  constructor(element) {
    this.init();
  }
  init() {
    const lenis = new Lenis({ lerp: 0.1 });
    lenis.on("scroll", () => ScrollTrigger.update());
    const scrollFn = (time) => {
      lenis.raf(time);
      requestAnimationFrame(scrollFn);
    };
    requestAnimationFrame(scrollFn);
    // Smooth anchor scrolling.
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener("click", function (e) {
        e.preventDefault();
        var href = this.getAttribute("href");
        lenis.scrollTo(href);
      });
    });
  }
}
