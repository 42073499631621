import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export class Statistic {
  constructor(el) {
    this.element = el;
    this.animate();
  }
  animate() {
    gsap.fromTo(
      this.element,
      {
        y: () => gsap.utils.random(128, 256),
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: this.element,
          start: "top top+=75%",
          end: "top top+=50%",
          scrub: 0,
          once: true
        },
      }
    );
  }
}
