const delay = (n) => {
  n = n || 2000;
  return new Promise((done) => {
    setTimeout(() => {
      done();
    }, n);
  });
};

const backdropFilterFix = () => {
  if (/apple/i.test(navigator.vendor)) {
    document.querySelectorAll(".glass").forEach((el) => {
      el.style.setProperty("backdrop-filter", "blur(16px)");
      el.style.setProperty("-webkit-backdrop-filter", "blur(16px)");
    });
  }
};

export { delay, backdropFilterFix };
