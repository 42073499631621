import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export class ScrollUp {
  constructor(element) {
    if (!element || !(element instanceof HTMLElement)) {
      throw new Error("Invalid element provided.");
    }
    this.element = element;
    this.init();
  }
  init() {
    gsap.to(this.element, {
      yPercent: -20,
      ease: "none",
      scrollTrigger: {
        trigger: this.element,
        start: "top bottom-=15%",
        end: "bottom top",
        scrub: 2,
      },
    });
  }
}
