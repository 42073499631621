import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export class ParallaxEffect {
  constructor(el) {
    this.container = el;
    this.mm = gsap.matchMedia();
    this.elements = this.container.querySelectorAll("[data-parallax-target]");
    this.animate();
  }
  MouseMoveHandler(e) {
    this.elements.forEach((item, i) => {
      let animFactor = item.dataset.speed ?? 0.008;
      let deltaX = (e.clientX - window.innerWidth / 2) * animFactor * (i / 2);
      let deltaY = (e.clientY - window.innerHeight / 2) * animFactor * (i / 2);
      gsap.to(item, {
        x: deltaX,
        y: deltaY,
        duration: 1,
        ease: "power3.out",
        //overwrite: true,
      });
    });
  }
  animate() {
    this.mm.add("(min-width: 1024px)", () => {
      console.log("desktop");
      document.addEventListener(
        "mousemove",
        this.MouseMoveHandler.bind(this),
        false
      );
      return () => {
        console.log("mobile");
        gsap.killTweensOf(this.elements);
        document.removeEventListener(
          "mousemove",
          this.MouseMoveHandler.bind(this),
          false
        );
      };
    });
  }
}
