import * as CookieConsent from "vanilla-cookieconsent";

export class CookieConsentModule {
  constructor(el) {

    CookieConsent.run({
      guiOptions: {
        consentModal: {
          layout: "box",
          position: "bottom left",
          equalWeightButtons: true,
          flipButtons: false,
        },
        preferencesModal: {
          layout: "box",
          position: "right",
          equalWeightButtons: true,
          flipButtons: false,
        },
      },
      categories: {
        necessary: {
          readOnly: true,
        },
        functionality: {},
        analytics: {},
      },
      language: {
        default: "en",
        autoDetect: "browser",
        translations: {
          en: {
            consentModal: {
              title: "We use cookies to give you the best experience. <img src=\"/wp-content/themes/kensa/images/kensa-cookie.png\" height=\"81px\" width=\"90px\" alt=\"Cookie image\">",
              description: "",
              acceptAllBtn: "Accept all",
              acceptNecessaryBtn: "Reject all",
              showPreferencesBtn: "Manage",
            },
            preferencesModal: {
              title: "Consent Preferences",
              acceptAllBtn: "Accept all",
              acceptNecessaryBtn: "Reject all",
              savePreferencesBtn: "Save preferences",
              closeIconLabel: "Close modal",
              serviceCounterLabel: "Service|Services",
              sections: [
                {
                  title: "Welcome visitor!",
                  description: "Select your cookie preferences below.",
                },
                {
                  title: "Strictly Necessary cookies",
                  description:
                    "These cookies are essential for the proper functioning of the website and cannot be disabled.",
                  linkedCategory: "necessary",
                },
                {
                  title: "Performance and Analytics",
                  description:
                    "These cookies collect information about how you use our website. All of the data is anonymized and cannot be used to identify you.",
                  linkedCategory: "analytics",
                },
                {
                  title: "Functionality Cookies",
                  description:
                    "These cookies control the look and functionality of this website. Disabling these can effect your experience of this website.",
                  linkedCategory: "functionality",
                },
                {
                  title: "More information",
                  description:
                    'For any queries in relation to our cookie policy and your choices, please contact our team.',
                },
              ],
            },
          },
        },
      },
    });
    
  }
}
