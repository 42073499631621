import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from "gsap/SplitText";

gsap.registerPlugin(ScrollTrigger, SplitText);

export class TypingEffect {
  constructor(textElement) {
    if (!textElement || !(textElement instanceof HTMLElement)) {
      throw new Error("Invalid text element provided.");
    }
    this.textElement = textElement;
    this.textFromColor = this.textElement.dataset.effectFrom;
    this.textToColor = this.textElement.dataset.effectTo;
    this.textAccentColor = this.textElement.dataset.effectAccent;
    this.initializeEffect();
  }
  initializeEffect() {
    this.splitter = new SplitText(this.textElement, { type: "words,chars" });
    this.animate();
  }
  animate() {
    if (this.splitter.chars.length > 0) {
      gsap.fromTo(
        this.splitter.chars,
        {
          color: this.textFromColor,
        },
        {
          ease: "none",
          color: (i, el) =>
            el.closest("span") ? this.textAccentColor : this.textToColor,
          stagger: 0.05,
          scrollTrigger: {
            trigger: this.textElement,
            start: "top top+=80%",
            end: "top top+=20%",
            scrub: 1,
            once: true,
          },
        }
      );
    }
  }
}
