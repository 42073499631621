import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export class PinZoom {
  constructor(el) {
    this.triggerElement = el;
    this.pinnedElement = this.triggerElement.querySelector("[data-pin-target]");
    this.init();
  }
  init() {

    gsap.set(this.pinnedElement, { width: '60%' })

    let tl = gsap.to(this.pinnedElement, {
      width: '100%',
      scrollTrigger: {
        trigger: this.triggerElement,
        start: "top top+=25%",
        end: "+=150vh",
        scrub: 1,
        pin: true,
      },
    });

  }
}
