// --------------------------------------------------
// Vendor
// --------------------------------------------------

import { gsap } from "gsap";

// --------------------------------------------------
// Classes
// --------------------------------------------------

import { SmoothPageScroll } from "./js/SmoothPageScroll";
import { Cursor } from "./js/Cursor";
import { TypingEffect } from "./js/TypingEffect";
import { RevealEffect } from "./js/RevealEffect";
import { ParallaxEffect } from "./js/ParallaxEffect";
import { BackgroundColor } from "./js/BackgroundColor";
import { Statistic } from "./js/Statistic";
import { VideoScrollScrub } from "./js/VideoScrollScrub";
import { PinZoom } from "./js/PinZoom";
import { HeaderVideoScrub } from "./js/HeaderVideoScrub";
import { VideoCard } from "./js/VideoCard";
import { SlideReveal } from "./js/SlideReveal";
import { backdropFilterFix } from "./js/Utils";
import { ScrollUp } from "./js/ScrollUp";
import { ParallaxEffectContained } from "./js/ParallaxEffectContained";
import { ScrollGallery } from "./js/ScrollGallery";
import { initSwipers } from "./js/Swipers";
import { TeamMemberCard } from "./js/TeamMemberCard";
import { CookieConsentModule } from "./js/Cookie";

// --------------------------------------------------
// onBeforeUnload
// --------------------------------------------------

window.onbeforeunload = function () {
  window.scrollTo(0, 0);
};

// --------------------------------------------------
// onLoad
// --------------------------------------------------

window.addEventListener("load", (e) => {
  // backdrop blur fix for safari
  backdropFilterFix();

  // smooth scrolling
  new SmoothPageScroll();

  // custom cursor
  new Cursor(".js-cursor");

  // background gradient transitions
  new BackgroundColor();

  // reveal media
  gsap.utils.toArray("[data-reveal]").forEach((el) => new RevealEffect(el));

  // reveal text
  gsap.utils.toArray("[data-effect]").forEach((el) => new TypingEffect(el));

  // parallax mousemove effect
  gsap.utils.toArray("[data-parallax]").forEach((el) => new ParallaxEffect(el));

  // statistics effect
  gsap.utils.toArray("[data-stat]").forEach((el) => new Statistic(el));

  // pin and zoom effect
  gsap.utils.toArray("[data-pin]").forEach((el) => new PinZoom(el));

  // video scrub effect
  gsap.utils.toArray("[data-scrub]").forEach((el) => new VideoScrollScrub(el));

  // slide reveal
  gsap.utils
    .toArray("[data-slide-reveal]")
    .forEach((el) => new SlideReveal(el));

  // header video scrub effect
  gsap.utils
    .toArray("[data-header-video]")
    .forEach((el) => new HeaderVideoScrub(el));

  // header video scrub effect
  gsap.utils.toArray("[data-video-card]").forEach((el) => new VideoCard(el));

  // fact cards
  gsap.utils.toArray("[data-col]").forEach((el) => new ScrollUp(el));

  // parallax team members
  gsap.utils
    .toArray("[data-parallax-contained]")
    .forEach((el) => new ParallaxEffectContained(el));

  // hover team members
  gsap.utils
    .toArray("[data-team-member]")
    .forEach((el) => new TeamMemberCard(el));

  // scrolling gallery
  gsap.utils
    .toArray("[data-scroll-gallery]")
    .forEach((el) => new ScrollGallery(el));

  // Load swipers
  initSwipers();

  // Load cookie consent module
  new CookieConsentModule();

  // preloader
  var preloader = document.querySelector(".preloader");

  preloader.classList.remove("active");

  setTimeout(() => {
    preloader.remove();
  }, 1000);
});

// Mobile menu toggle

var mobileMenuToggle = document.querySelector(".mobile-menu-toggle"),
  mobileMenu = document.querySelector(".mobile-menu"),
  header = document.querySelector("header"),
  mobileSubmenus = document.querySelectorAll(".mobile-submenu-toggle");

mobileMenuToggle.addEventListener("click", () => {
  if (mobileMenuToggle.classList.contains("active")) {
    mobileMenuToggle.classList.remove("active");
  } else {
    mobileMenuToggle.classList.add("active");
  }

  if (mobileMenu.classList.contains("active")) {
    mobileMenu.classList.remove("active");
    header.classList.remove("active");
  } else {
    mobileMenu.classList.add("active");
    header.classList.add("active");
  }

  mobileSubmenus.forEach((mobileSubmenu) => {
    mobileSubmenu.classList.remove("active");
  });
});

// Post ajax loading

var blogFilters = document.querySelectorAll(".blog-filter");

if (blogFilters) {
  blogFilters.forEach((blogFilter) => {
    blogFilter.addEventListener("click", () => {
      var targetId = blogFilter.dataset.id;

      blogFilters.forEach((item) => {
        if (item.dataset.id != targetId) {
          item.classList.remove("active");
        } else {
          if (item.classList.contains("active")) {
            item.classList.remove("active");
          } else {
            item.classList.add("active");
          }
        }
      });

      var currentActive = document.querySelector(".blog-filter.active");

      if (currentActive) {
        var currentActiveFilter = currentActive.dataset.id;
      } else {
        var currentActiveFilter = false;
      }

      var page = 0,
        data = [
          {
            page: page,
            filter: currentActiveFilter,
            type: "index",
          },
        ];

      postLoader(data, "replace");
    });
  });
}

document.body.addEventListener("click", function (event) {
  if (event.target.id == "post-loader") {
    var page = event.target.dataset.page,
      blogFilters = document.querySelectorAll(".blog-filter");
    container = document.getElementById("post-target");

    if (blogFilters) {
      var activeItem = document.querySelectorAll(".blog-filter.active");

      if (activeItem.length > 0) {
        var activeFilter = activeItem.dataset.id;
      } else {
        var activeFilter = false;
      }
    } else {
      var activeFilter = container.dataset.category;
    }

    var data = [
      {
        page: page,
        filter: activeFilter,
        type: container.dataset.type,
      },
    ];

    postLoader(data, "amend");
  }
});

function postLoader(data, containerAction) {
  var xhttp = new XMLHttpRequest(),
    postLoaderButton = document.getElementById("post-loader"),
    postTarget = document.getElementById("post-target");

  xhttp.open("POST", "/wp-admin/admin-ajax.php", true);
  xhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
  xhttp.send("action=post_loader&data=" + JSON.stringify(data));

  xhttp.onreadystatechange = function () {
    if (xhttp.readyState === 4 && xhttp.status === 200) {
      if (postLoaderButton) {
        postLoaderButton.parentElement.remove();
      }

      if (containerAction == "amend") {
        postTarget.insertAdjacentHTML("beforeend", xhttp.responseText);
      } else {
        postTarget.innerHTML = "";
        postTarget.innerHTML = xhttp.responseText;
      }

      lazyLoader();
    }
  };
}

// Project ajax loading

var projectLoaderButton = document.getElementById("project-loader"),
  projectFilters = document.querySelectorAll(".category-filter");

if (projectLoaderButton) {
  var projectTarget = document.getElementById("project-target"),
    projectLoaderButton = document.getElementById("project-loader");

  const sectionObserver = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          var button = document.getElementById("project-loader");

          if (button) {
            var page = button.dataset.page,
              activeFilters = document.querySelectorAll(
                ".category-filter.active"
              ),
              data = [
                {
                  page: page,
                  filter: [],
                },
              ];

            if (activeFilters) {
              activeFilters.forEach((activeFilter) => {
                var id = activeFilter.dataset.term;

                data[0]["filter"].push(id);
              });
            }

            projectLoader(data, "append");
          }
        }
      });
    },
    {
      rootMargin: "-100% 0px 0px 0px",
      threshold: 0,
    }
  );

  sectionObserver.observe(projectTarget);
}

if (projectFilters) {
  projectFilters.forEach((projectFilter) => {
    projectFilter.addEventListener("click", () => {
      projectFilter.classList.toggle("active");

      var activeFilters = document.querySelectorAll(".category-filter.active"),
        data = [
          {
            page: 0,
            filter: [],
          },
        ];

      if (activeFilters) {
        activeFilters.forEach((activeFilter) => {
          var id = activeFilter.dataset.term;

          data[0]["filter"].push(id);
        });
      }

      projectLoader(data, "empty");
    });
  });
}

function projectLoader(data, target) {
  var xhttp = new XMLHttpRequest(),
    projectLoaderButton = document.getElementById("project-loader"),
    projectTarget = document.getElementById("project-target");

  xhttp.open("POST", "/wp-admin/admin-ajax.php", true);
  xhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
  xhttp.send("action=project_loader&data=" + JSON.stringify(data));

  xhttp.onreadystatechange = function () {
    if (xhttp.readyState === 4 && xhttp.status === 200) {
      if (projectLoaderButton) {
        projectLoaderButton.parentElement.remove();
      }

      if (target == "append") {
        projectTarget.insertAdjacentHTML("beforeend", xhttp.responseText);
      } else {
        projectTarget.innerHTML = "";
        projectTarget.insertAdjacentHTML("beforeend", xhttp.responseText);
      }

      lazyLoader();
    }
  };
}

// Single post sticky links

var stickyLinkTargets = document.querySelectorAll(
  ".single-content-area h1[id], .single-content-area h2[id], .single-content-area h3[id], .single-content-area h4[id], .single-content-area h5[id], .single-content-area h6[id]"
);

if (stickyLinkTargets) {
  let mm = gsap.matchMedia();

  mm.add("(min-width: 1024px)", () => {
    const sectionObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const id = entry.target.id;

          if (entry.intersectionRatio > 0) {
            document
              .querySelector('.sticky-link[href="#' + id + '"]')
              .classList.add("kensa-text-light-gradient", "font-bold");
          } else {
            document
              .querySelector('.sticky-link[href="#' + id + '"]')
              .classList.remove("kensa-text-light-gradient", "font-bold");
          }
        });
      },
      {
        rootMargin: "0px 0px 0px",
      }
    );

    stickyLinkTargets.forEach((stickyLinkTarget) => {
      sectionObserver.observe(stickyLinkTarget);
    });
  });
}

// FAQ toggle

var faqToggles = document.querySelectorAll(".faq-toggle"),
  faqContents = document.querySelectorAll(".faq-content");

if (faqToggles) {
  faqToggles.forEach((faqToggle) => {
    faqToggle.addEventListener("click", (e) => {
      var targetId = faqToggle.dataset.id,
          faqGroup = faqToggle.dataset.set;

      faqToggles.forEach((item) => {
        if (item.dataset.id != targetId) {
          item.classList.remove("active");
        }
      });

      faqContents.forEach((item) => {
        if (item.dataset.id != targetId) {
          item.style.maxHeight = null;
          item.classList.remove("active");
        }
      });

      document
        .querySelector('.faq-toggle[data-id="' + targetId + '"][data-set="' + faqGroup + '"]')
        .classList.toggle("active");

      var targetContent = document.querySelector(
        '.faq-content[data-id="' + targetId + '"][data-set="' + faqGroup + '"]'
      );

      if (
        targetContent.style.maxHeight != null &&
        targetContent.style.maxHeight != ""
      ) {
        targetContent.style.maxHeight = null;
        targetContent.classList.remove("active");
      } else {
        targetContent.style.maxHeight = targetContent.scrollHeight + "px";
        targetContent.classList.add("active");
      }
    });
  });
}

// Feature slider

var sections = gsap.utils.toArray(".panel"),
  panelContainer = document.querySelector(".panel-container");

if (sections && panelContainer) {
  gsap.to(sections, {
    xPercent: -100 * (sections.length - 1),
    ease: "none",
    scrollTrigger: {
      trigger: ".panel-container",
      pin: true,
      scrub: 1,
      snap: {
        snapTo: 1 / (sections.length - 1),
        duration: { min: 0.2, max: 0.4 },
        ease: "power1.inOut",
      },
      end: () => "+=" + panelContainer.offsetWidth,
    },
  });
}

// Menu toggles

var toggles = document.querySelectorAll(".submenu-toggle"),
  submenus = document.querySelectorAll(".menu-submenu");

if (toggles) {
  toggles.forEach((toggle) => {
    toggle.addEventListener("click", () => {
      var target = toggle.dataset.toggle;

      toggles.forEach((item) => {
        if (item.dataset.toggle === target) {
          if (item.classList.contains("active")) {
            item.classList.remove("active");
          } else {
            item.classList.add("active");
          }
        } else {
          item.classList.remove("active");
        }
      });

      submenus.forEach((submenu) => {
        if (submenu.dataset.id === target) {
          if (submenu.classList.contains("active")) {
            submenu.classList.remove("active");
          } else {
            submenu.classList.add("active");
          }
        } else {
          submenu.classList.remove("active");
        }
      });
    });
  });
}

// Mobile submenu toggles

var mobileSubmenuToggles = document.querySelectorAll(".mobile-submenu-toggle"),
  mobileSubmenus = document.querySelectorAll(".mobile-submenu");

if (mobileSubmenuToggles) {
  mobileSubmenuToggles.forEach((mobileMenuToggle) => {
    mobileMenuToggle.addEventListener("click", () => {
      var targetId = mobileMenuToggle.dataset.submenu;

      mobileSubmenuToggles.forEach((item) => {
        if (item.dataset.submenu === targetId) {
          if (item.classList.contains("active")) {
            item.classList.remove("active");
          } else {
            item.classList.add("active");
          }
        } else {
          item.classList.remove("active");
        }
      });

      mobileSubmenus.forEach((submenu) => {
        if (submenu.dataset.id === targetId) {
          if (submenu.classList.contains("active")) {
            submenu.classList.remove("active");
          } else {
            submenu.classList.add("active");
          }
        } else {
          submenu.classList.remove("active");
        }
      });
    });
  });
}

// Mobile submenu close

var submenuClosers = document.querySelectorAll(".mobile-submenu-close");

if (submenuClosers) {
  submenuClosers.forEach((submenuCloser) => {
    submenuCloser.addEventListener("click", () => {
      var targetMenuId = submenuCloser.dataset.id;

      mobileSubmenus.forEach((submenu) => {
        if (submenu.dataset.id === targetMenuId) {
          if (submenu.classList.contains("active")) {
            submenu.classList.remove("active");
          } else {
            submenu.classList.add("active");
          }
        } else {
          submenu.classList.remove("active");
        }
      });

      mobileSubmenuToggles.forEach((mobileMenuToggle) => {
        mobileMenuToggle.classList.remove("active");
      });
    });
  });
}

// Header - responsive fix to reset menu items

window.addEventListener("resize", () => {
  var header = document.querySelector("header"),
    mobileMenuToggle = document.querySelector(".mobile-menu-toggle"),
    toggles = document.querySelectorAll(".submenu-toggle"),
    submenus = document.querySelectorAll(".menu-submenu"),
    mobileMenu = document.querySelector(".mobile-menu"),
    mobileSubmenuToggles = document.querySelectorAll(".mobile-submenu-toggle"),
    mobileSubmenus = document.querySelectorAll(".mobile-submenu");

  let mm = gsap.matchMedia();

  mm.add("(min-width: 1024px)", () => {
    header.classList.remove("active");

    if (mobileSubmenuToggles) {
      mobileSubmenuToggles.forEach((item) => {
        item.classList.remove("active");
      });
    }

    if (mobileSubmenus) {
      mobileSubmenus.forEach((submenu) => {
        submenu.classList.remove("active");
      });
    }

    mobileMenu.classList.remove("active");
    mobileMenuToggle.classList.remove("active");
  });

  mm.add("(max-width: 1024px)", () => {
    if (toggles) {
      toggles.forEach((toggle) => {
        toggle.classList.remove("active");
      });
    }

    if (submenus) {
      submenus.forEach((submenu) => {
        submenu.classList.remove("active");
      });
    }
  });
});

// Fix background colour on load

window.addEventListener("load", () => {
  var sections = gsap.utils.toArray("[data-bg]"),
    loadedSection = sections[0];

  if (loadedSection) {
    var startBackgroundFrom = loadedSection.dataset.bgfrom,
      startBackgroundTo = loadedSection.dataset.bgto;

    gsap.to("body", {
      background: `linear-gradient(to right, ${startBackgroundFrom} 0%, ${startBackgroundTo} 100%)`,
      overwrite: "false",
    });
  }
});

// Audio player

var audioToggle = document.querySelector(".audio-toggle"),
  audioTarget = document.querySelector(".audio-target");

if (audioToggle && audioTarget) {
  audioToggle.addEventListener("click", () => {
    audioTarget.play();
  });
}

// Lazy load assets

function lazyLoader() {
  let lazyImages = document.querySelectorAll(".lazy-image"),
    lazyVideos = document.querySelectorAll(".lazy-video");

  if ("IntersectionObserver" in window) {
    let lazyImageObserver = new IntersectionObserver(function (
      entries,
      observer
    ) {
      entries.forEach(function (entry) {
        if (entry.isIntersecting) {
          let lazyImage = entry.target;

          lazyImage.src = lazyImage.dataset.src;

          if (lazyImage.dataset.srcset) {
            lazyImage.srcset = lazyImage.dataset.srcset;
          }

          lazyImage.classList.remove("lazy-image");
          lazyImageObserver.unobserve(lazyImage);
        }
      });
    });

    lazyImages.forEach(function (lazyImage) {
      lazyImageObserver.observe(lazyImage);
    });

    let lazyVideoObserver = new IntersectionObserver(function (
      entries,
      observer
    ) {
      entries.forEach(function (video) {
        if (video.isIntersecting) {
          for (let source in video.target.children) {
            let videoSource = video.target.children[source];

            if (
              typeof videoSource.tagName === "string" &&
              videoSource.tagName === "SOURCE"
            ) {
              videoSource.src = videoSource.dataset.src;
            }
          }

          video.target.load();
          video.target.classList.remove("lazy");
          lazyVideoObserver.unobserve(video.target);
        }
      });
    });

    lazyVideos.forEach(function (lazyVideo) {
      lazyVideoObserver.observe(lazyVideo);
    });
  }
}

window.addEventListener("load", () => {
  lazyLoader();
});


// Price accordion

var priceAccordions = document.querySelectorAll('.pricing-toggle');

if (priceAccordions) {
  priceAccordions.forEach(
    priceAccordion => {

      priceAccordion.addEventListener(
        'click',
        () => {

          priceAccordion.classList.toggle('rotate-180');

          var target = priceAccordion.dataset.target,
              targetDiv = document.getElementById('accordion-collapse-body-' + target);

              console.log(target);

          targetDiv.classList.toggle('hidden');

        }
      );

    }
  );
}


// Scroll based text

var scrollTextSections = document.querySelectorAll('.animate-line-group');

if (scrollTextSections) {
  scrollTextSections.forEach(
    scrollTextSection => {

      const items = scrollTextSection.querySelectorAll('.animated-line');

      var i = 1;

      items.forEach(
        item => {

          const [x, xEnd] = (i % 2) ? ['0', (item.scrollWidth * -1)/10] : [(item.scrollWidth * -1)/10, 0];

          gsap.fromTo(
            item,
            { x },
            {
              x: xEnd,
              scrollTrigger: { 
                trigger: scrollTextSection, 
                scrub: 0.5 
              }
            }
          );

          i++;

        }
      );

    }
  );
}