import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export class RevealEffect {
  constructor(element) {
    if (!element || !(element instanceof HTMLElement)) {
      throw new Error("Invalid element provided.");
    }
    this.element = element;
    this.animate();
  }
  animate() {
    gsap.fromTo(
      this.element,
      {
        clipPath: "polygon(0 0, 0 0, 0 100%, 0 100%)",
      },
      {
        clipPath: "polygon(0 0, 100% 0%, 100% 100%, 0 100%)",
        duration: 1,
        ease: "power3",
        scrollTrigger: {
          trigger: this.element,
          start: "center bottom",
          end: "bottom top",
          toggleActions: "play none none none",
        },
      }
    );
  }
}
