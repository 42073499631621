import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export class ScrollGallery {
  constructor(element) {
    if (!element || !(element instanceof HTMLElement)) {
      throw new Error("Invalid element provided.");
    }
    this.mm = gsap.matchMedia();
    this.element = element;
    this.direction = this.element.dataset.direction;
    this.x;
    this.init();
  }
  init() {
    if (this.direction == "left") {
      this.mm.add("(min-width: 641px)", () => {
        gsap.set(this.element, { x: 0 });
        this.x = (this.element.scrollWidth - this.element.offsetWidth) * -1;
      });
      this.mm.add("(max-width: 640px)", () => {
        gsap.set(this.element, { x: 100 });
        this.x = (this.element.scrollWidth/2 - this.element.offsetWidth+200) * -1;
      });
    } else {
      this.mm.add("(min-width: 641px)", () => {
        gsap.set(this.element, { x: -(this.element.scrollWidth/2) });
        this.x = -((this.element.scrollWidth/3 - (this.element.offsetWidth/2)) * -1);
      });
      this.mm.add("(max-width: 640px)", () => {
        gsap.set(this.element, { x: -(this.element.scrollWidth/4) });
        this.x = -((this.element.scrollWidth/2 - (this.element.offsetWidth+600)) * -1);
      });
    }
    gsap.to(this.element, {
      ease: "none",
      x: () => this.x,
      scrollTrigger: {
        trigger: this.element,
        start: "top bottom",
        end: "bottom center",
        scrub: 0.5,
        invalidateOnRefresh: true,
      },
    });
  }
}
