import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export class VideoScrollScrub {
  constructor(el) {
    this.triggerElement = el;
    this.video = this.triggerElement.querySelector("[data-scrub-target]");
    //console.log(el);
    this.init();
  }
  init() {
    gsap.timeline({
      defaults: { duration: 1 },
      scrollTrigger: {
        trigger: this.triggerElement,
        ease: "none",
        start: "top bottom",
        end: "+=100%",
        scrub: true,
        onUpdate: (self) => {
          //console.log(self.progress);
          this.video.currentTime = (self.progress / 14) * 100 - 1;
          // -1 fixes an issue on safari where the video disappears at the end of the scrollTrigger
        },
      },
    });
  }
}
