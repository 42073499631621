import Swiper from "swiper";
import { Autoplay, Navigation, Pagination } from "swiper/modules";

const initSwipers = () => {
  let myContentSwiper = document.querySelector(".myContentSwiper");
  if (myContentSwiper) {
    new Swiper(myContentSwiper, {
      modules: [Navigation, Pagination, Autoplay],
      grabCursor: true,
      spaceBetween: 0,
      effect: "fade",
      loop: true,
      autoplay: {
        delay: 5000,
      },
      pagination: {
        el: ".feature-pagination",
        clickable: true,
      },
    });
  }
  let testimonialsSwiper = document.querySelector(".testimonialsSwiper");
  if (testimonialsSwiper) {
    new Swiper(testimonialsSwiper, {
      modules: [Navigation, Pagination],
      slidesPerView: 1,
      spaceBetween: 24,
      breakpoints: {
        640: {
          slidesPerView: 2,
        },
        1024: {
          slidesPerView: 2.5,
        },
      },
      navigation: {
        nextEl: '.testimonial-next',
        prevEl: '.testimonial-prev',
      },
    });
  }
};

export { initSwipers };