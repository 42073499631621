import { gsap } from "gsap";

export class Cursor {
  constructor(el) {
    this.cursor = document.querySelector(el);
    this.items = document.querySelectorAll([
      "button",
      ".submenu-toggle",
      "a",
      "[data-team-member]",
      ".swiper-navigation > div"
    ]);
    this.defaults = { duration: 0.3, ease: "power3" };
    this.xTo = gsap.quickTo(this.cursor, "x", { ...this.defaults });
    this.yTo = gsap.quickTo(this.cursor, "y", { ...this.defaults });
    this.mm = gsap.matchMedia();
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.init();
  }
  init() {
    //console.log('Initialising...');
    this.mm.add(
      {
        isDesktop: `(min-width: 1024px) and (prefers-reduced-motion: no-preference)`,
        isMobile: `(max-width: 1023px) and (prefers-reduced-motion: no-preference)`,
      },
      (context) => {
        let { isDesktop, isMobile } = context.conditions;
        if (isDesktop) {
          this.animate();
        }
        return () => {
          this.cursor.classList.add("hidden");
          window.removeEventListener("mousemove", this.onMouseMove, false);
          this.items.forEach((item) => {
            item.removeEventListener("mouseenter", this.onMouseEnter, false);
            item.removeEventListener("mouseleave", this.onMouseLeave, false);
          });
        };
      }
    );
  }
  onMouseMove(e) {
    //console.log('Moving...');
    this.xTo(e.clientX);
    this.yTo(e.clientY);
  }
  onMouseEnter(e) {
    //console.log('Enter...');
    gsap.to(this.cursor, {
      scale: 2.5,
      duration: 0.3,
      ease: "back",
      overwrite: "auto",
      opacity: 0.5
    });
  }
  onMouseLeave(e) {
    //console.log('Leave...');
    gsap.to(this.cursor, {
      scale: 1,
      duration: 0.3,
      ease: "back",
      overwrite: "auto",
      opacity: 1
    });
  }
  animate() {
    //console.log('Animating...');
    this.cursor.classList.remove("hidden");
    gsap.set(this.cursor, { xPercent: -50, yPercent: -50 });
    window.addEventListener("mousemove", this.onMouseMove);
    this.items.forEach((item) => {
      item.addEventListener("mouseenter", this.onMouseEnter);
      item.addEventListener("mouseleave", this.onMouseLeave);
    });
  }
}
