import { gsap } from "gsap";

export class TeamMemberCard {
  constructor(el) {
    this.el = el;
    this.img = this.el.querySelector("[data-team-member-img]");
    this.imgDefault = this.img.querySelectorAll("img")[0];
    this.imgHover = this.img.querySelectorAll("img")[1];
    this.caption = this.el.querySelector("[data-team-member-caption]");
    this.title = this.el.querySelector("[data-team-member-title]");
    this.dibber = this.el.querySelector("[data-team-member-dibber]");
    this.para = this.el.querySelector("[data-team-member-description]");
    this.el.addEventListener("click", this.MouseClickHandler.bind(this), false);
    this.tl = gsap.timeline({
      paused: true,
      defaults: { ease: "power3", duration: 0.4, overwrite: true },
    });
    this.init();
  }

  MouseClickHandler(e) {
    let y = gsap.getProperty(this.caption, "y") == 78 ? 0 : "78%";
    let r = gsap.getProperty(this.dibber, "rotation") == 0 ? 45 : 0;
    let o = gsap.getProperty(this.para, "opacity") == 0 ? 1 : 0;

    if (o == 1) {
      this.tl.to(this.caption, { y: y });
      this.tl.to(this.dibber, { rotation: r }, "-=0.4");
      this.tl.to(this.para, { opacity: o }, "-=0.1");
    } else {
      this.tl.to(this.para, { opacity: o });
      this.tl.to(this.caption, { y: y }, "-=0.2");
      this.tl.to(this.dibber, { rotation: r }, "-=0.4");
    }

    this.tl.play();
  }
  init() {
    gsap.set(this.caption, { y: "78%" });
  }
}
